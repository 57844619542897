/** @jsx jsx */
import { jsx, Flex, Box } from 'theme-ui';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { locNavigate } from '../state/session';
import { Themed } from '@theme-ui/mdx';
import { DomesticParcelIcon, MultiParcelIcon2 } from './Icon';

export default ({ sxx, title, description, lessThan100, moreThan100, lessThan100Path, moreThan100Path }) => {
  const dispatch = useDispatch();

  const handleGoToRequestQuote = useCallback(() => {
    dispatch(locNavigate(moreThan100Path));
    window && window.scrollTo(0, 0);
  }, [moreThan100Path, dispatch]);

  const handleGoToDetailsPage = useCallback(() => {
    const currentPath = window.location.pathname;
    dispatch(locNavigate(lessThan100Path, `?returnPath=${currentPath}`));
  }, [lessThan100Path]);

  return (
    <Box sx={{ ...sxx, my: 5 }}>
      {title && <Themed.h2>{title}</Themed.h2>}
      {description && <Themed.p sx={{ fontWeight: 'semibold', color: 'primary' }}>{description}</Themed.p>}
      <Flex
        sx={{
          width: '100%',
          flexDirection: ['column', null, null, 'row'],
          gap: [3, null, null, 4],
        }}
      >
        <Option
          icon={<DomesticParcelIcon width="120" height="120" />}
          optionText={lessThan100}
          onSelect={handleGoToDetailsPage}
        />
        <Option
          icon={<MultiParcelIcon2 width="120" height="120" />}
          optionText={moreThan100}
          onSelect={handleGoToRequestQuote}
        />
      </Flex>
    </Box>
  );
};

const Option = ({ icon, optionText, onSelect }) => {
  return (
    <Flex
      role="button"
      tabIndex={0}
      aria-label={optionText}
      onClick={() => onSelect && onSelect()}
      sx={{
        ':hover': {
          bg: 'primary',
          div: {
            color: 'white',
          },
          span: {
            color: 'white',
          },
        },
        width: '100%',
        cursor: 'pointer',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: 3,
        borderColor: 'border',
        borderStyle: 'solid',
        borderWidth: 2,
        flex: 1,
      }}
    >
      <div sx={{ color: 'primary' }}>{icon && icon}</div>
      <span sx={{ fontSize: 4, mb: icon ? 3 : 0, fontWeight: 'bold', color: 'primary' }}>{optionText}</span>
    </Flex>
  );
};
