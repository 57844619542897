import { useStaticQuery, graphql } from 'gatsby';
import { useSelector } from 'react-redux';
import { checkLanguageVersion } from '../utils/contentful';
import { filterByChannel } from './useBlogPosts';

export const useLatestPosts = () => {
  const locale = useSelector((state) => state.session.locale);
  const { blog, press } = useStaticQuery(graphql`
    query LatestPosts {
      blog: allContentfulBlogPost(filter: { postType: { eq: "Blog" } }, sort: { publishTime: DESC }) {
        edges {
          node {
            id
            title
            slug
            tags
            image {
              gatsbyImageData(width: 480, placeholder: BLURRED, formats: [AUTO, WEBP])
            }
            publishTime(formatString: "D.M.Y")
            languages
            node_locale
            channel
          }
        }
      }
      press: allContentfulBlogPost(filter: { postType: { eq: "Press" } }, sort: { publishTime: DESC }) {
        edges {
          node {
            id
            title
            slug
            publishTime(formatString: "D.M.Y")
            node_locale
            languages
            channel
          }
        }
      }
    }
  `);
  const pressPosts = press.edges
    .filter(filterByChannel)
    .filter((b) => b?.node?.node_locale.startsWith(locale) && checkLanguageVersion(locale, b?.node?.languages))
    .slice(0, 3);
  const blogPosts = blog.edges
    .filter(filterByChannel)
    .filter((b) => b?.node?.node_locale.startsWith(locale) && checkLanguageVersion(locale, b?.node?.languages))
    .slice(0, 2);
  return { pressPosts, blogPosts };
};
