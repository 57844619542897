import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { useSelector } from 'react-redux';

const translateName = (to, locale, data, fallback) => {
  if (!to || !locale) return fallback;
  if (data.allSitePage && data.allSitePage.nodes) {
    const node = data.allSitePage.nodes.find(
      (node) => node?.pageContext?.paths && Object.values(node?.pageContext?.paths)?.includes(to)
    );
    if (node && node.pageContext && node.pageContext.titles && node.pageContext.titles[locale]) {
      return node.pageContext.titles[locale];
    }
  }
  return fallback;
};

export const PageTitle = ({ to, fallback, ...rest }) => {
  const locale = useSelector((state) => state.session.locale);
  const data = useStaticQuery(graphql`
    query PageLinksQuery {
      allSitePage {
        nodes {
          pageContext
        }
      }
    }
  `);

  const translatedName = translateName(to, locale, data, fallback);
  return <span {...rest}>{translatedName}</span>;
};
