/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from '../components';
import { isBrowser } from '../utils';
import ReCAPTCHA from 'react-google-recaptcha';
import { useFormikContext } from 'formik';

const RecaptchaButton = ({
  translate,
  isSubmitting,
  field = 'recaptcha',
  buttonText = 'feedback.send',
  nocaptcha = false,
  disabled = false,
  setFieldValue,
  handleSubmit,
  sxx,
}) => {
  const [token, setToken] = useState();
  const { setFieldValue: formikSetFieldValue, handleSubmit: formikHandleSubmit, errors } = useFormikContext() || {};
  const recaptchaInstance = useRef();

  const setField = useMemo(() => {
    return formikSetFieldValue || setFieldValue;
  }, [setFieldValue, formikSetFieldValue]);

  const submitCaptcha = useMemo(() => {
    return formikHandleSubmit || handleSubmit;
  }, [handleSubmit, formikHandleSubmit]);

  const resetRecaptcha = () => {
    recaptchaInstance && recaptchaInstance.current && recaptchaInstance.current.reset();
  };

  const handleExecute = useCallback(async () => {
    if (nocaptcha) {
      setField(field, 'nocaptcha');
      setToken('nocaptcha');
      return;
    }
    const token = await recaptchaInstance.current.executeAsync();
    setToken(token);
    setField(field, token);
  }, [recaptchaInstance, nocaptcha]);

  useEffect(() => {
    const hasErrors = errors && Object.keys(errors).length > 0;
    if (token && !hasErrors) {
      submitCaptcha();
    }
  }, [token, submitCaptcha]);

  const reset = () => {
    resetRecaptcha();
    setField(field, '');
  };

  return (
    <Box sx={{ mt: 3 }}>
      {isBrowser && !nocaptcha && (
        <ReCAPTCHA
          ref={recaptchaInstance}
          size="invisible"
          sitekey={process.env.GOOGLE_RECAPTCHA_SITE_KEY}
          onExpired={reset}
        />
      )}
      <Button type="submit" onClick={handleExecute} disabled={disabled || isSubmitting} sx={sxx}>
        {translate(buttonText)}
      </Button>
    </Box>
  );
};

export default RecaptchaButton;
