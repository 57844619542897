/** @jsx jsx */
import { jsx, Flex, Box } from 'theme-ui';
import { Themed } from '@theme-ui/mdx';
import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { DotIcon } from '../components/Icon';
import Link from '../components/Link';
import Logo from '../components/Logo';
import capitalize from 'lodash/capitalize';

export default ({ post, frontPage = false }) => (
  <Box
    as={Link}
    to={`/blog/${post.slug}`}
    sx={{
      width: '100%',
      color: 'text',
      ':hover': {
        color: 'primary',
      },
    }}
  >
    {post.image ? (
      <GatsbyImage
        image={getImage(post.image)}
        sx={{
          borderRadius: 2,
          height: frontPage ? [240, 270] : [240, 300],
          mb: 3,
          bg: 'secondary',
          /*
          img: {
            mixBlendMode: 'screen',
          }, // */
        }}
      />
    ) : (
      <Flex
        sx={{
          height: frontPage ? [240, 270] : [240, 300],
          borderRadius: 2,
          mb: 3,
          alignItems: 'center',
          justifyContent: 'center',
          bg: 'primary',
        }}
      >
        <Logo sx={{ color: 'white', transform: 'scale(1.5)' }} />
      </Flex>
    )}
    <Flex sx={{ alignItems: 'center', mb: 1 }}>
      {post.publishTime}
      {post.tags && (
        <>
          <DotIcon sx={{ flex: 'none', mx: 2 }} />
          {post.tags.map((tag, i) => (
            <span key={i}>
              {i > 0 && ', '}
              {capitalize(tag)}
            </span>
          ))}
        </>
      )}
    </Flex>
    <Themed.strong sx={{ fontSize: 2, lineHeight: 'heading' }}>{post.title}</Themed.strong>
  </Box>
);
