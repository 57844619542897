/** @jsx jsx */
import { jsx, Box, Flex, Grid, Container } from 'theme-ui';
import { Themed } from '@theme-ui/mdx';
import Link from './Link';
import { FooterLogo } from './Logo';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SocialLinks from './SocialLinks';
import { getLinkForPage } from '../utils/links';
import { useFooterLinks } from '../hooks/useFooterLinks';

const FooterTabItem = ({ link, title }) => (
  <Link
    partiallyActive={true}
    to={link}
    sx={{
      cursor: 'pointer',
      position: 'relative',
      m: 0,
      fontSize: 3,
      color: 'white',
      fontWeight: 'medium',
      ':hover': {
        color: 'white',
        opacity: 0.8,
      },
      '&::after': {
        position: 'absolute',
        bottom: -2,
        left: 2,
        right: 2,
        height: 2,
        bg: 'white',
        content: '""',
        width: 0,
      },
      '&[aria-current="page"]::after': {
        width: [0, 0, 0, 'auto'],
      },
    }}
  >
    {title}
  </Link>
);

const FooterTabs = ({ translate }) => (
  <Grid gap={4} sx={{ gridTemplateColumns: 'repeat(auto-fill, minmax(60px, auto))' }}>
    <FooterTabItem link="/" title={translate('navigation.private')} />
    <FooterTabItem link="/yrityksille" title={translate('navigation.business')} />
  </Grid>
);

export default (props) => {
  const { t: translate } = useTranslation();
  const locale = useSelector((state) => state.session.locale);
  const { customerLinks, corporateLinks } = useFooterLinks();
  let links = props.corporate ? corporateLinks : customerLinks;
  const contentModules = (links.find((node) => node.node_locale.startsWith(locale)) || {}).contentModules || [];
  return (
    <Box
      aria-hidden={props?.['aria-hidden']}
      as="footer"
      sx={{
        bg: 'secondary',
        color: 'white',
        py: [4, 5],
      }}
    >
      <Container variant="wide">
        <FooterTabs translate={translate} />
        <Grid
          sx={{
            gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
            mt: 3,
          }}
        >
          {contentModules.map((linkList, i) => (
            <Box key={i}>
              {linkList.headline && (
                <Themed.h2
                  sx={{
                    fontSize: [2, 18],
                    fontWeight: 'medium',
                    color: 'white',
                  }}
                  children={linkList.headline}
                />
              )}
              {linkList.links && (
                <Flex sx={{ flexDirection: 'column' }}>
                  {linkList.links
                    .filter((link) => link && (link.page || link.url))
                    .map((link, j) => {
                      return (
                        <Link
                          key={j}
                          noTitleFix
                          to={link.page ? getLinkForPage(link.page) : link.url}
                          sx={{
                            py: 1,
                            fontSize: 2,
                            lineHeight: 'heading',
                            color: 'white',
                            ':hover': { opacity: 0.8 },
                          }}
                        >
                          {link.title}
                        </Link>
                      );
                    })}
                </Flex>
              )}
            </Box>
          ))}
          <Box sx={{ mt: [5, 0] }}>
            <Themed.h2
              sx={{
                display: ['none', 'block'],
                fontSize: [2, 18],
                fontWeight: 'medium',
                color: 'white',
              }}
              children={translate('footer.socialMedia')}
            />
            <SocialLinks />
          </Box>
        </Grid>
      </Container>

      <Flex sx={{ flexDirection: 'column', alignItems: 'center', pt: 5, pb: 4 }}>
        <FooterLogo sx={{ color: 'white' }} />
        <span aria-hidden={true} sx={{ fontSize: '12.8px', fontWeight: 500, mt: 2, userSelect: 'none' }}>
          {translate('footer.slogan', { fallbackLng: 'en' })}
        </span>
      </Flex>
    </Box>
  );
};
