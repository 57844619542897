/** @jsx jsx */
import React from 'react';
import { jsx, Flex } from 'theme-ui';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';

const Tooltip = ({ children, tooltip, hideArrow, ...props }) => {
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    trigger: ['click', 'hover'],
    ...props,
  });

  return (
    <>
      <span ref={setTriggerRef}>{children}</span>

      {visible && (
        <Flex
          ref={setTooltipRef}
          {...getTooltipProps({ className: 'tooltip-container' })}
          sx={{
            bg: 'secondary',
            color: 'white',
            borderRadius: 1,
            border: 'none',
            boxShadow: 'dropdown',
            py: 12,
            px: 3,
            m: 2,
            transition: 'opacity .25s',
            zIndex: 389383,
            fontFamily: 'body',
            fontSize: 1,
            maxWidth: ['80vw', '360px'],
          }}
        >
          {tooltip}
        </Flex>
      )}
    </>
  );
};

export default Tooltip;
