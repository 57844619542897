/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import { Themed } from '@theme-ui/mdx';
import Accordion from '../Accordion';

export default ({ content }) => {
  return (
    <Box variant="layout.text">
      <Themed.h2 sx={{ variant: 'text.title', mt: 0 }}>{content.headline}</Themed.h2>
      <Box>
        {content.frequentlyAskedQuestions &&
          content.frequentlyAskedQuestions.map((faq, i) => (
            <Accordion variant="faq" title={faq.question} key={i}>
              <Box
                dangerouslySetInnerHTML={{
                  __html: faq.answer.childMarkdownRemark.html,
                }}
                sx={{ lineHeight: 'normal', a: { textDecoration: 'underline' } }}
              />
            </Accordion>
          ))}
      </Box>
    </Box>
  );
};
