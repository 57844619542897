import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { useSelector } from 'react-redux';
import translatePath from '../utils/translatePath';
import Button from './Button';

const LocalizedButton = ({ to, ...props }) => {
  const data = useStaticQuery(graphql`
    query AllSitePageButtonLinkQuery {
      allSitePage {
        nodes {
          pageContext
        }
      }
    }
  `);
  const locale = useSelector((state) => state.session.locale);
  const locTo = translatePath(to, locale, data);
  return <Button to={locTo} {...props} />;
};

export default LocalizedButton;
