import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { deviceDetect } from 'react-device-detect';
import testLogger from '../components/test-logger';
import * as analytics from './analytics';
import _ from 'lodash';

const MAX_EMAIL_FILE_SIZE = 5.9 * 1024 * 1024;
const API_TIMEOUT_FOR_FILES = 30000;
const API_TIMEOUT_FOR_LOGIN = 20000;

const apiServer = axios.create({
  baseURL: process.env.GATSBY_API_URL,
  timeout: 20000, // 5 seconds is not always enough
});

const ticketServer = axios.create({
  baseURL: process.env.GATSBY_TICKET_API_URL,
  timeout: 5000,
});

export class UserAuthError extends Error {
  constructor(message) {
    super(message);
    this.name = 'UserAuthError';
  }
}

export class FileSizeError extends Error {
  constructor(message) {
    super(message);
    this.name = 'FileSizeError';
  }
}

const checkTokens = async () => {
  let atoken = localStorage.getItem('atoken');
  let rtoken = localStorage.getItem('rtoken');
  if (atoken == null) {
    // cannot renew without atoken (username)
    if (rtoken !== null) localStorage.removeItem('rtoken');
    return;
  }
  if (rtoken == null) return;
  if (jwtDecode(atoken).exp <= Date.now() / 1000 + 60) {
    await renewTokens(rtoken, atoken);
  }
};

export const renewTokens = async (rtoken, atoken) => {
  try {
    const { AccessToken, IdToken, ExpiresIn } = await refreshTokens(rtoken, atoken);
    localStorage.setItem('itoken', IdToken);
    localStorage.setItem('atoken', AccessToken);
    setAccessTokenCookie(AccessToken, null, ExpiresIn);
    return { AccessToken, IdToken };
  } catch (error) {
    localStorage.removeItem('rtoken');
    return;
  }
};

const refreshTokens = async (refreshToken, accessToken) => {
  const response = await apiServer.post(
    '/user/token/refresh',
    { accessToken },
    { headers: { Authorization: refreshToken } }
  );
  if (!response.data || !response.data.AuthenticationResult || !response.data.AuthenticationResult.AccessToken) {
    throw new Error('Refresh failed');
  }
  return response.data.AuthenticationResult;
};

const getTokenFromStorage = (name) => {
  if (localStorage.getItem(name) !== null) {
    return localStorage.getItem(name);
  }
  return sessionStorage.getItem(name);
};

const parseJwt = (token) => {
  const middlePart = (token || '').split('.')[1];
  if (!middlePart) {
    return;
  }
  const base64 = middlePart.replace(/-/g, '+').replace(/_/g, '/');

  try {
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    return JSON.parse(jsonPayload);
  } catch (error) {
    return;
  }
};

export const setAccessTokenCookie = (AccessToken, RefreshToken, ExpiresIn) => {
  if (ExpiresIn) {
    document.cookie = `atoken=${AccessToken}; max-age=${ExpiresIn}; domain=matkahuolto.fi; path=/; secure`;
  } else {
    const payload = parseJwt(AccessToken);
    if (payload && payload.exp) {
      document.cookie = `atoken=${AccessToken}; expires=${new Date(
        payload.exp * 1000
      ).toGMTString()}; domain=matkahuolto.fi; path=/; secure`;
    } else {
      document.cookie = `atoken=${AccessToken}; max-age=3600; domain=matkahuolto.fi; path=/; secure`;
    }
  }
  // if (RefreshToken) { // why was this if here? why lippukauppa uses this cookie at all?
  document.cookie = `signed-in=true; max-age=31536000; domain=matkahuolto.fi; path=/`; // refresh token age may be shorter than one year
  // }
};

const getAccessTokenFromCookie = () => {
  const cookies = document.cookie.split(';').reduce((res, c) => {
    const [key, val] = c.trim().split('=').map(decodeURIComponent);
    try {
      return Object.assign(res, { [key]: JSON.parse(val) });
    } catch (e) {
      return Object.assign(res, { [key]: val });
    }
  }, {});
  return cookies.atoken;
};

export const getToken = () => {
  let token = getTokenFromStorage('atoken');
  if (!token) {
    token = getAccessTokenFromCookie();
  }
  return token;
};

export const getIdToken = () => {
  return getTokenFromStorage('itoken');
};

export const getProducts = async () => {
  try {
    const response = await apiServer.get('/products', { params: { v: 3 } });
    if (response.status !== 200) {
      const msg = (response.data && response.data.errorMessage) || `${response.status}: ${response.statusText}`;
      throw new Error(msg);
    }
    if (!Array.isArray(response.data)) {
      throw new Error('Response is not an array.');
    }
    return response.data;
  } catch (error) {
    testLogger.log('getProducts:FAILED ' + error + ', ' + JSON.stringify(deviceDetect()));
    throw error;
  }
};

export const getProductsForCountry = async (countryCode, language) => {
  const response = await apiServer.get(`/products/${countryCode}`, {
    params: { language, v: 3 },
  });
  return response.data;
};

export const loadBasket = async (basketId) => {
  if (getToken()) {
    await checkTokens();
  }
  try {
    const response = await apiServer.get(
      `/basket/${basketId}`,
      getToken()
        ? {
            headers: { Authorization: getToken() },
          }
        : undefined
    );
    if (response.status !== 200) {
      const msg = (response.data && response.data.errorMessage) || `${response.status}: ${response.statusText}`;
      throw new Error(msg);
    }

    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return null;
    }
    throw error;
  }
};

export const putBasket = async (basket) => {
  if (getToken()) {
    await checkTokens();
  }
  const response = await apiServer.put(
    `/basket/${basket.basketId}`,
    { ...basket, src: 'web' },
    getToken()
      ? {
          headers: { Authorization: getToken() },
        }
      : undefined
  );
  return response.data;
};

export const deleteBasket = async (basketId) => {
  await apiServer.delete(`/basket/${basketId}`);
};

export const applyDiscount = async (basketId, code) => {
  if (!code) {
    return '';
  }
  const props = { params: { code, src: 'web' } };
  if (getToken()) {
    await checkTokens();
    props.headers = { Authorization: getToken() };
  }

  try {
    const response = await apiServer.put(`/basket/${basketId}/discount`, {}, props);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return 'UKNOWN_CODE';
    }
    if (error.response && error.response.data && error.response.data.code) {
      return error.response.data.code; // MISSING_PRODUCT, TOTAL_TOO_LOW, EXPIRED, ALREADY_USED, TOO_EARLY, MOBILE_ONLY
    }
    throw error;
  }
};

export const checkRepackCode = async (code) => {
  try {
    await apiServer.get(`/repack/check`, { params: { code } });
    return;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return 'UKNOWN_CODE';
    }
    if (error.response && error.response.data && error.response.data.code) {
      return error.response.data.code; // CODE_ALREADY_USED, NOT_YET_ACTIVE, EXPIRED
    }
    throw error;
  }
};

export const createOrder = async (basket, paymentOptions, language, discountPercent) => {
  const anyParcelIncludesPickup = basket.items.some(
    (item) =>
      !item.buySeries && (item.product.pickupIncluded || (item.services || []).some((s) => s.override?.pickupIncluded))
  );

  const body = {
    basketId: basket.basketId,
    sender: basket.sender,
    rows: basket.items.map(
      ({
        country,
        product,
        buySeries,
        services,
        pickupPoint,
        departurePoint,
        recipient,
        packages,
        repackCode,
        content,
        contents,
        jys,
        delivery,
        connection,
      }) => ({
        product: product.id,
        buySeries,
        packages,
        repackCode,
        services: services && services.map((s) => s.serviceCode),
        pickupPoint: pickupPoint && pickupPoint.officeCode,
        departurePoint: departurePoint && departurePoint.officeCode,
        recipient: { ...recipient, country },
        content,
        contents,
        jys,
        delivery,
        connection: _.pick(connection, ['id', 'fromPlace', 'toPlace', 'companies', 'line']),
      })
    ),
    pickup: anyParcelIncludesPickup && !basket.noPickup ? 'FREE' : basket.pickup && basket.pickup.serviceCode,
    discountCode: (basket.discount && basket.discount.code) || (discountPercent ? 'email-based' : undefined),
    ...paymentOptions, // paymentMethod, agreementNumber, saveCard
    language,
  };
  const options = {
    params: { v: 3 },
  };

  if (getToken()) {
    await checkTokens();
  }
  const token = getToken();
  if (token) {
    options.headers = { Authorization: token };
  }
  const response = await apiServer.post('/order/create', body, options);
  return response.data;
};

export const payOrder = async (orderId) => {
  await apiServer.put(`/order/${orderId}/pay`);
};

export const getOrder = async (orderId, transactionId) => {
  const response = await apiServer.get(`/order/${orderId}`, {
    params: { transactionId },
  });
  return response.data;
};

export const completeOrder = async (orderId, transactionId) => {
  try {
    const response = await apiServer.put(`/order/complete/${orderId}`, {
      transactionId,
    });
    return response.data;
  } catch (error) {
    return { state: 'ERROR' };
  }
};

export const getOrderStatus = async (orderId, transactionId) => {
  try {
    const response = await apiServer.get(`/order/${orderId}`, {
      params: { transactionId },
    });
    return response.data;
  } catch (error) {
    return { state: 'ERROR' };
  }
};

export const getOrderDetailsForShipment = async (shipmentNumber) => {
  try {
    await checkTokens();
    const response = await apiServer.get(`/shipment/${shipmentNumber}`, {
      headers: { Authorization: getToken() },
      params: { v: 3 },
    });
    return response.data;
  } catch (error) {
    if (error.response && (error.response.status === 404 || error.response.status === 403)) {
      return;
    }
    console.warn(`Get order details ${shipmentNumber} failed: `, error);
  }
};

export const updateRecipient = async (shipmentNumber, recipient) => {
  await checkTokens();
  const response = await apiServer.post(`/shipment/${shipmentNumber}`, recipient, {
    headers: { Authorization: getToken() },
  });
  return response.data;
};

export const autocompleteAddress = async (term, language = 'fi', country = 'FI') => {
  const searchTerm = term.trim();
  if (searchTerm.length < 2) {
    return [];
  }
  const params = { query: searchTerm, language, country };

  try {
    let retry = 0;
    let response;
    while (!response && retry < 2) {
      retry += 1;
      try {
        response = await apiServer.get('/search/address', {
          params,
          timeout: retry * 2000,
        });
      } catch (error) {
        if (error.code !== 'ECONNABORTED') {
          // not timeout, no retry
          throw error;
        }
      }
    }

    if (!response || response.status !== 200 || !Array.isArray(response.data)) {
      return [];
    }
    return response.data;
  } catch (error) {
    console.warn('autocomplete failed:', error);
    return [];
  }
};

export const getAddressSuggestions = async (term, language = 'fi', country = 'FI') => {
  const searchTerm = term.trim();
  if (searchTerm.length < 2) {
    return [];
  }
  const params = { query: searchTerm, language, country };

  try {
    let retry = 0;
    let response;
    while (!response && retry < 3) {
      retry += 1;
      try {
        response = await apiServer.get('/search/autocomplete', {
          params,
          timeout: retry * 2000,
        });
      } catch (error) {
        if (error.code !== 'ECONNABORTED') {
          // not timeout, no retry
          throw error;
        }
      }
    }

    if (!response || response.status !== 200 || !Array.isArray(response.data)) {
      return [];
    }
    return response.data;
  } catch (error) {
    console.warn('autocomplete failed:', error);
    return [];
  }
};

export const getPlaceCoordinates = async (id) => {
  try {
    const response = await apiServer.get('/search/location', {
      params: { id },
    });
    return response.data;
  } catch (error) {
    // nop
  }
};

export const autocompleteBusLocation = async (search, lang = 'fi') => {
  if (search.length < 2) {
    return [];
  }
  try {
    const response = await ticketServer.get('/location/', {
      params: { search, lang },
    });
    if (response.status !== 200 || !Array.isArray(response.data)) {
      return [];
    }
    return response.data;
  } catch (error) {
    console.warn('autocompleteBusLocation failed:', error);
    return [];
  }
};

export const ParcelService = {
  PICKUP: 'P1',
  SEND_SMALL: 'P2',
  SEND_LARGE: 'P3',
};

export const getServicePointById = async (id, service, language = 'fi') => {
  try {
    const response = await apiServer.get('/search/offices', {
      params: { id, service, language },
    });
    return response.data;
  } catch (error) {
    console.warn('getServicePointById failed:', error);
    return [];
  }
};

export const getServicePoints = async (postalCode, streetAddress, service, language = 'fi') => {
  try {
    const response = await apiServer.get('/search/offices', {
      params: { postalCode, streetAddress, service, language },
    });
    return response.data;
  } catch (error) {
    console.warn('getServicePoints failed:', error);
  }
};

export const getServicePointsFromArea = async (left, right, top, bottom) => {
  try {
    const response = await apiServer.get(
      `/search/offices/area?left=${left}&right=${right}&top=${top}&bottom=${bottom}`
    );
    return response.data;
  } catch (error) {
    console.warn('getServicePointsFromArea faied', error);
  }
};

export const getPostOfficeByCode = async (postalCode, country, lang = 'fi') => {
  try {
    const response = await apiServer.get('/search/postalCode', {
      params: { n: postalCode, country, lang },
    });
    return response.data;
  } catch (error) {
    console.warn('getPostOfficeByCode failed:', error);
  }
};

export const getPostalCodeByName = async (officeName, country, lang = 'fi') => {
  try {
    const response = await apiServer.get('/search/postOffice', {
      params: { name: officeName, country, lang },
    });
    return response.data;
  } catch (error) {
    console.warn('getPostalCodeByName failed:', error);
  }
};

export const getServicePointServices = async (type, language) => {
  try {
    const response = await apiServer.get('/services', {
      params: { type, language },
    });
    return response.data;
  } catch (error) {
    console.warn('getServicePointServices failed:', error);
  }
};

export const getPickupPoints = async ({
  term,
  street,
  postcode,
  city,
  country,
  isLarge,
  isExtraLarge,
  isHeavy,
  overrideSearchType,
}) => {
  try {
    const params = {
      term,
      street,
      postcode,
      city,
      country,
      isLarge,
      isExtraLarge,
      isHeavy,
      n: 25,
      overrideSearchType,
    };
    // this api does not return open times, phone number, or services

    let retry = 0;
    let response;
    while (!response && retry < 3) {
      retry += 1;
      try {
        response = await apiServer.get('/search/pickup', {
          params,
          timeout: retry * 3300,
        });
      } catch (error) {
        if (error.code !== 'ECONNABORTED') {
          // not timeout, no retry
          throw error;
        }
      }
    }
    return (response && response.data) || [];
  } catch (error) {
    console.warn('getPickupPoints failed:', error);
  }
};

export const getRepackPoints = async ({ street, postcode }) => {
  try {
    // this api does not return open times, phone number, or services
    const response = await apiServer.get('/search/mhown', {
      params: { street, postcode },
    });
    return response.data;
  } catch (error) {
    console.warn('getRepackPoints failed:', error);
  }
};

export const getExpressParcelPoints = async ({ street, postcode, language }) => {
  try {
    // this api does not return open times, phone number, or services
    const response = await apiServer.get('/search/express', {
      params: { street, postcode, language },
    });
    return response.data;
  } catch (error) {
    console.warn('getExpressParcelPoints failed:', error);
  }
};

export const getLargeParcelDeparturePoints = async ({ street, postcode, language }) => {
  try {
    // this api does not return open times, phone number, or services
    const response = await apiServer.get('/search/ppDeparture', {
      params: {
        street,
        postcode,
        lang: language || 'fi',
      },
    });
    return response.data;
  } catch (error) {
    console.warn('getRepackPoints failed:', error);
  }
};

export const getSeriesProduct = async (email, productId, language = 'fi') => {
  try {
    const props = { params: { email, language } };
    if (getToken()) {
      await checkTokens();
      props.headers = { Authorization: getToken() };
    }
    const response = await apiServer.get(`/series/${productId}`, props);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return null;
    }
    if (error.response && error.response.status === 401) {
      throw { authorizationRequired: true };
    }
    console.warn('getSeriesProduct failed:', error);
    throw error;
  }
};

export const updateSeriesShipment = async (
  { email, productId, isUnused, shipmentNumber, recipient, pickupPoint },
  language = 'fi'
) => {
  const body = { shipmentNumber, isUnused, recipient, pickupPoint };
  const props = { params: { email, language } };
  if (getToken()) {
    await checkTokens();
    props.headers = { Authorization: getToken() };
  }
  const response = await apiServer.post(`/series/${productId}`, body, props);
  return response.data;
};

export const forgotSeriesNumber = async (email, language = 'fi') => {
  await apiServer.put('/series/forgot', null, {
    params: { email, language },
  });
};

export const getTrackingInfo = async (parcelNumber, language = 'fi') => {
  try {
    const props = {
      params: { language, parcelNumber },
    };
    if (getToken()) {
      await checkTokens();
      props.headers = { Authorization: getToken() };
    }
    const response = await apiServer.get('/search/trackingInfo', props);
    return response.data;
  } catch (error) {
    console.warn('getTrackingInfo failed:', error);
    return { err: error };
  }
};

export const login = async (username, password) => {
  const response = await apiServer.post('/user/auth', { username, password });
  if (response.status !== 200) {
    const msg = (response.data && response.data.errorMessage) || `${response.status}: ${response.statusText}`;
    throw new Error(msg);
  }
  if (!response.data || !response.data.AuthenticationResult || !response.data.AuthenticationResult.AccessToken) {
    throw new Error('Login failed');
  }
  return response.data.AuthenticationResult;
};

export const getUser = async (token, check = true) => {
  if (check) {
    await checkTokens();
    token = getToken();
  }
  const response = await apiServer.get('/user', {
    headers: { Authorization: token },
    timeout: API_TIMEOUT_FOR_LOGIN,
  });
  if (response.status !== 200) {
    const msg = (response.data && response.data.errorMessage) || `${response.status}: ${response.statusText}`;
    throw new Error(msg);
  }
  if (!response.data) {
    throw new Error('Getting user failed');
  }
  if (!response.data.email) {
    throw new UserAuthError('Missing email');
  }
  analytics.setUserId(response.data);
  return response.data;
};

export const register = async (user) => {
  try {
    const response = await apiServer.post('/user/create', user);
    return response.data;
  } catch (error) {
    console.warn('Registrating Failed: ', error);
    if (error.response && error.response.data && error.response.data.code) {
      error.code = error.response.data.code;
    }
    throw error;
  }
};

export const modifyPassword = async (details) => {
  try {
    await checkTokens();
    const response = await apiServer.post('/user/password/modify', details, {
      headers: { Authorization: getToken() },
    });
    return response.data;
  } catch (error) {
    console.warn('Modify Password Failed: ', error);
    throw error;
  }
};

export const requestResetPassword = async (username, language = 'fi', recaptcha) => {
  try {
    const response = await apiServer.post('/user/password/reset/request', {
      email: username,
      language,
      recaptcha,
    });
    return response.data;
  } catch (error) {
    console.warn('Reset Password Failed: ', error);
    throw error;
  }
};

export const resetPassword = async (password, token) => {
  try {
    const response = await apiServer.post('/user/password/reset', {
      token,
      newPassword: password,
    });
    return response.data;
  } catch (error) {
    console.warn('Reset Password Failed: ', error);
    throw error;
  }
};

export const verifyEmail = async (user, validationId) => {
  try {
    const response = await apiServer.get(`/user/emailValidate/${user}/${validationId}`);
    return response.data;
  } catch (error) {
    console.warn('Email Verification Failed: ', error);
    throw error;
  }
};

export const checkEmailValidation = async (userId) => {
  try {
    const response = await apiServer.get(`/user/checkEmailValidate/${userId}`);
    return response.data;
  } catch (error) {
    console.warn('Check Email Verification Failed: ', error);
    throw error;
  }
};

export const resendValidationEmail = async (username, language = 'fi') => {
  try {
    const response = await apiServer.post('/user/resendValidationEmail', {
      email: username,
      language,
    });
    return response.data;
  } catch (error) {
    console.warn('Email Resend Validation Email Failed: ', error);
    throw error;
  }
};

export const resendValidationEmailWithToken = async (email, language = 'fi') => {
  try {
    await checkTokens();
    const response = await apiServer.post(
      '/user/resendValidationEmailWithToken',
      {
        email,
        language,
      },
      { headers: { Authorization: getToken() } }
    );
    return response.data;
  } catch (error) {
    console.warn('Email Resend Validation Email Failed: ', error);
    throw error;
  }
};

export const requestPhoneVeficationSMS = async () => {
  await checkTokens();
  try {
    const language = 'fi';
    const response = await apiServer.put('/user/phone/code', { language }, { headers: { Authorization: getToken() } });
    return response.data;
  } catch (error) {
    console.warn('Request phone number verification failed: ', error);
    throw error;
  }
};

export const verifyPhoneNumber = async (code) => {
  await checkTokens();
  try {
    const response = await apiServer.post('/user/phone/verify', { code }, { headers: { Authorization: getToken() } });
    return response.data;
  } catch (error) {
    console.warn('Email Resend Validation Email Failed: ', error);
    throw error;
  }
};

export const updateProfile = async (details) => {
  try {
    await checkTokens();
    const response = await apiServer.post(
      '/user',
      {
        ...details,
      },
      { headers: { Authorization: getToken() } }
    );
    return response.data;
  } catch (error) {
    console.warn('Email Resend Validation Email Failed: ', error);
    throw error;
  }
};

export const removeProfile = async () => {
  await checkTokens();
  await apiServer.delete('/user', {
    headers: { Authorization: getToken() },
    data: { src: 'Paketit' },
  });
};

export const getCards = async () => {
  await checkTokens();
  const response = await apiServer.get(`/user/paymentAgreement`, {
    headers: { Authorization: getToken() },
  });
  return response.data;
};

export const generateQrLoginLink = async (language) => {
  await checkTokens();
  const response = await apiServer.post(
    `/user/qr-login`,
    { language },
    {
      headers: { Authorization: getToken() },
    }
  );
  return response.data;
};

export const removeCard = async (id) => {
  await checkTokens();
  const response = await apiServer.delete(`/user/paymentAgreement/${id}`, {
    headers: { Authorization: getToken() },
  });
  return response.data;
};

const convertToFormData = (vo) => {
  // split by files and other
  const formData = new FormData();
  let attachmentSize = 0;
  const [attachments, other] = Object.entries(vo).reduce(
    ([attachments, other], [key, value]) => {
      if (value instanceof File) {
        return [[...attachments, [key, value]], other];
      }
      if (Array.isArray(value) && value.length > 0 && value[0] instanceof File) {
        return [[...attachments, ...value.map((v) => [key, v])], other];
      }
      return [attachments, [...other, [key, value]]];
    },
    [[], []]
  );
  other.map((entry) => formData.append(entry[0], entry[1]));
  for (const [i, [key, value]] of attachments.entries()) {
    attachmentSize = attachmentSize + value.size;
    // should be same as in the backend
    formData.append('attachment', value);
  }
  if (attachmentSize >= MAX_EMAIL_FILE_SIZE) {
    throw new FileSizeError('file size exceeded');
  }
  return formData;
};

export const sendFeedback = async (vo) => {
  await apiServer.post('/forms/feedback', convertToFormData(vo), {
    headers: { 'Content-Type': 'multipart/form-data' },
    timeout: API_TIMEOUT_FOR_FILES,
  });
};

export const sendScamMessageForm = async (vo) => {
  await apiServer.post('/forms/scam-messages', convertToFormData(vo), {
    'Content-Type': 'multipart/form-data; charset=UTF-8',
    timeout: API_TIMEOUT_FOR_FILES,
  });
};

export const sendSalesContactForm = async (vo) => {
  await apiServer.post('/forms/sales-contact', convertToFormData(vo), {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const sendEInvoicingForm = async (vo) => {
  await apiServer.post('/forms/e-invoicing', convertToFormData(vo), {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const sendEBusTicketCancellationForm = async (vo) => {
  await apiServer.post('/forms/e-bus-ticket-cancellation', convertToFormData(vo), {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const sendStrikeRefundForm = async (vo) => {
  await apiServer.post('/forms/strike-refund', vo, {
    headers: { 'Content-Type': 'application/json' },
  });
};

export const sendValueTicketOrderForm = async (vo) => {
  await apiServer.post('/forms/ticket-order-form', vo, {
    headers: { 'Content-Type': 'application/json' },
  });
};

export const sendParcelCancellationForm = async (vo) => {
  await apiServer.post('/forms/parcel-cancellation', vo, {
    headers: { 'Content-Type': 'application/json' },
  });
};

export const sendJobApplication = async (vo) => {
  await apiServer.post('/forms/job-application', convertToFormData(vo), {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const sendFormDisturbanceOnlineStore = async (vo) => {
  await apiServer.post('/forms/online-store-disturbance', convertToFormData(vo), {
    headers: { 'Content-Type': 'multipart/form-data' },
    timeout: API_TIMEOUT_FOR_FILES,
  });
};

export const sendClaimDamagesForm = async (vo) => {
  await apiServer.post('/forms/claim-damages', convertToFormData(vo), {
    headers: { 'Content-Type': 'multipart/form-data' },
    timeout: API_TIMEOUT_FOR_FILES,
  });
};

export const checkContractNumber = async (contractNumber) => {
  const response = await apiServer.get(`/pickup/customer/${contractNumber}`);
  return response.data && response.data.valid;
};

export const getPickupTimes = async (postalCode) => {
  const response = await apiServer.get(`/pickup/time/${postalCode}`);
  return response.data;
};

export const sendPickupOrder = async (order) => {
  await apiServer.post('/pickup/order', order, { timeout: 22000 }); // todo timeout
};

export const getReceivedParcels = async (offset) => {
  await checkTokens();
  const response = await apiServer.get(`/history/parcel/received/${offset || ''}`, {
    headers: { Authorization: getToken() },
  });
  return response.data;
};

export const getSentParcels = async (offset) => {
  await checkTokens();
  const response = await apiServer.get(`/history/parcel/sent/${offset || ''}`, {
    headers: { Authorization: getToken() },
  });
  return response.data;
};

export const getMultiParcelHistory = async (language) => {
  await checkTokens();
  const response = await apiServer.get('/history/series', {
    params: { v: 2, language },
    headers: { Authorization: getToken() },
  });
  return response.data;
};

export const archiveShipment = async (shipmentNumber) => {
  await checkTokens();
  const response = await apiServer.put(
    `/history/archive/${shipmentNumber}`,
    {},
    { headers: { Authorization: getToken() } }
  );
  return response.data;
};

export const archiveShipments = async (shipments) => {
  await checkTokens();
  const response = await apiServer.post(`/history/archive`, { shipments }, { headers: { Authorization: getToken() } });
  return response.data;
};

export const unarchiveShipment = async (shipmentNumber) => {
  await checkTokens();
  const response = await apiServer.put(
    `/history/unarchive/${shipmentNumber}`,
    {},
    { headers: { Authorization: getToken() } }
  );
  return response.data;
};

export const unarchiveShipments = async (shipments) => {
  await checkTokens();
  const response = await apiServer.post(
    `/history/unarchive`,
    { shipments },
    { headers: { Authorization: getToken() } }
  );
  return response.data;
};

export const getAggregateParcelPDF = async (startDate, endDate) => {
  await checkTokens();
  const params = { startDate, endDate };
  if (process.env.NODE_ENV === 'development') {
    params.html = true;
  }
  const response = await apiServer.get(`/receipt/orders`, {
    params,
    headers: { Authorization: getToken() },
  });
  return response.data;
};

export const getParcelReceipt = async (shipmentNumber) => {
  await checkTokens();
  const params = { shipmentNumber };
  if (process.env.NODE_ENV === 'development') {
    params.html = true;
  }
  const response = await apiServer.get(`/receipt/sendReceipt`, {
    params,
    headers: { Authorization: getToken() },
  });
  return response.data;
};

export const getTickets = async (future = 'true') => {
  await checkTokens();
  const response = await apiServer.get(`/history/tickets?future=${future}`, {
    headers: { Authorization: getToken() },
  });
  return response.data;
};

export const getTravelCards = async () => {
  await checkTokens();
  const response = await apiServer.get(`/history/mtun/cards`, {
    headers: { Authorization: getToken() },
  });
  return response.data;
};

export const getTravelCardDetails = async (cardId) => {
  await checkTokens();
  const response = await apiServer.get(`/history/mtun/${cardId}`, {
    headers: { Authorization: getToken() },
  });
  return response.data;
};

export const getFavoriteTrips = async () => {
  await checkTokens();
  const response = await apiServer.get(`/history/favoriteTrips`, {
    headers: { Authorization: getToken() },
  });
  return response.data;
};

export const checkIfDeliveryPossible = async (shipmentNumber, postalCode) => {
  const response = await apiServer.get('/delivery/check', {
    params: {
      shipmentNumber,
      postalCode,
    },
  });
  return response.data;
};

export const createDeliveryOrder = async (shipmentNumber, paymentOptions, language, delivery, token) => {
  const { name, email, address, phoneNumber, time: serviceCode, details, discount } = delivery;
  const body = {
    shipmentNumber,
    language,
    ...paymentOptions, // paymentMethod, agreementNumber, saveCard
    address,
    name,
    email,
    phoneNumber,
    serviceCode,
    details,
    token,
  };

  if (discount) {
    body.discountCode = discount.code;
  }

  if (getToken()) {
    await checkTokens();
  }
  const authToken = getToken();
  const options = {};
  if (authToken) {
    options.headers = { Authorization: authToken };
  }

  const response = await apiServer.post('/delivery/create', body, options);
  return response.data;
};

export const checkDiscountCode = async (code, serviceCode) => {
  try {
    const response = await apiServer.get(`/discount`, {
      params: { code, serviceCode, src: 'web' },
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.code) {
      return error.response.data.code; // UKNOWN_CODE, ALREADY_USED, WRONG_TYPE, EXPIRED, NOT_LOGGED_IN, UNKNOWN_ERROR, TOO_EARLY
    }
    throw error;
  }
};

export const getStorageTimeOptions = async (shipmentNumber) => {
  if (!shipmentNumber) {
    return { services: [] };
  }
  const response = await apiServer.get('/storagetime/price', {
    params: { shipmentNumber },
  });
  return response.data;
};

export const createStorageTimeOrder = async (
  direction,
  shipmentNumber,
  paymentOptions,
  language,
  serviceCode,
  discountCode
) => {
  const body = {
    direction,
    shipmentNumber,
    language,
    ...paymentOptions, // paymentMethod, agreementNumber, saveCard
    serviceCode,
    discountCode,
  };

  if (getToken()) {
    await checkTokens();
  }
  const response = await apiServer.post('/storagetime/create', body, {
    headers: { Authorization: getToken() },
  });
  return response.data;
};

export const createReturnOrder = async (shipmentNumber, paymentOptions, language, returnType, pickupPoint) => {
  const body = {
    shipmentNumber,
    language,
    returnType,
    pickupPoint,
    ...paymentOptions, // paymentMethod, agreementNumber, saveCard
  };

  if (getToken()) {
    await checkTokens();
  }
  const response = await apiServer.post('/returnToCustomer/create', body, {
    headers: { Authorization: getToken() },
  });
  return response.data;
};

export const createWoltDeliveryOrder = async (
  shipmentNumber,
  doorCode,
  deliveryRemarks,
  buildingType,
  reachInstructions,
  paymentOptions,
  language,
  emailAddress,
  details
) => {
  const body = {
    shipmentNumber,
    language,
    ...paymentOptions, // paymentMethod, agreementNumber, saveCard
    doorCode,
    buildingType,
    deliveryRemarks,
    reachInstructions,
    emailAddress,
    details,
  };

  if (getToken()) {
    await checkTokens();
  }
  const response = await apiServer.post('/wolt/create', body, {
    headers: { Authorization: getToken() },
  });
  return response.data;
};

export const getTransferPrice = async (shipmentNumber, destination) => {
  await checkTokens();
  const response = await apiServer.get('/transfer/price', {
    params: { shipmentNumber, destination },
    headers: { Authorization: getToken() },
  });
  return response.data;
};

export const createTransferOrder = async (shipmentNumber, destination, discount, paymentOptions, language) => {
  const body = {
    ...paymentOptions,
    shipmentNumber,
    destination: destination.officeCode,
    discountCode: discount?.code,
    language,
  };
  await checkTokens();
  const response = await apiServer.post('/transfer/create', body, {
    headers: { Authorization: getToken() },
  });
  return response.data;
};

export const createCustomsOrder = async (paymentDetails, paymentOptions, language) => {
  const body = {
    ...paymentDetails,
    language,
    ...paymentOptions, // paymentMethod, agreementNumber, saveCard
  };
  delete body.attachment;

  if (getToken()) {
    await checkTokens();
  }

  const response = await apiServer.post('/customs/create', body, {
    headers: { Authorization: getToken() },
  });

  return response.data;
};

export const isWoltAvailable = async (shipmentNumber, streetAddress, postalCode, city, checkPromise) => {
  const response = await apiServer.get(`/wolt/check/${shipmentNumber}`, {
    params: {
      streetAddress,
      postalCode,
      city,
      checkPromise: checkPromise || undefined,
    },
  });
  return response.data;
};

export const getDeliveryTimes = async (shipmentNumber, token, language, includePrices = false) => {
  const params = { token, language };
  if (includePrices) {
    params.prices = '1';
  }
  const response = await apiServer.get(`/delivery/${shipmentNumber}/time`, { params });
  return response.data;
};

export const getDeliveryServicePoints = async (shipmentNumber, token) => {
  const params = { token };
  const response = await apiServer.get(`/delivery/${shipmentNumber}/service-points`, { params });
  return response.data;
};

export const checkReturnToCustomer = async (shipmentNumber, token) => {
  const response = await apiServer.get(`/returnToCustomer/check/${shipmentNumber}`, { params: { token } });
  return response.data;
};

export const setDeliveryTime = async (shipmentNumber, body) => {
  const authToken = getToken();
  const options = {};
  if (authToken) {
    options.headers = { Authorization: authToken };
  }
  delete body.selectionDone;
  const response = await apiServer.post(`/delivery/${shipmentNumber}/time`, body, options);
  return response.data;
};

export const updateDeliveryRemarks = async (shipmentNumber, body) => {
  delete body.selectionDone;
  const response = await apiServer.post(`/delivery/${shipmentNumber}/remarks`, body);
  return response.data;
};

export const sendDeliveryConfirmationEmail = async (shipmentNumber, token, email, language) => {
  await apiServer.post(`/delivery/${shipmentNumber}/email`, {
    token,
    email,
    language,
  });
};

export const checkJysDelivery = async ({ name, street, apartment, postcode, city }) => {
  const response = await apiServer.get(`/delivery/jys`, {
    params: { name, street, apartment, postcode, city },
  });
  return response.data;
};

export const checkReturnCode = async (shipmentNumber, code) => {
  await checkTokens();
  const response = await apiServer.get('/return/checkCode', {
    params: { shipmentNumber, code },
    headers: { Authorization: getToken() },
  });
  return response.data;
};

export const getReturnPickupPrice = async (shipmentNumber, recaptcha) => {
  await checkTokens();
  const response = await apiServer.get('/return/pickup', {
    params: { shipmentNumber, recaptcha },
    headers: { Authorization: getToken() },
  });
  return response.data;
};

export const createReturnShipmentOrder = async (
  shipmentNumber,
  paymentOptions,
  language,
  returnCode,
  sender,
  discountCode,
  recaptcha
) => {
  const body = {
    shipmentNumber,
    language,
    ...paymentOptions, // paymentMethod, agreementNumber, saveCard
    sender,
    returnCode,
    discountCode,
    recaptcha,
  };

  if (getToken()) {
    await checkTokens();
  }
  const response = await apiServer.post('/return/order', body, {
    headers: { Authorization: getToken() },
  });
  return response.data;
};

export const loadProductUpgradeData = async (shipmentNumber) => {
  await checkTokens();
  const response = await apiServer.get(`/upgrade/${shipmentNumber}`, {
    headers: { Authorization: getToken() },
    params: { v: 3 },
  });
  return response.data;
};

export const createUpgradeOrder = async (shipmentNumber, order, paymentOptions, language, discount) => {
  const { product = {}, services = [], recipient } = order;
  const body = {
    product: product.id,
    services: services.map((s) => s.serviceCode),
    recipient,
    language,
    ...paymentOptions, // paymentMethod, agreementNumber, saveCard
  };
  if (discount) {
    body.discountCode = discount.code;
  }

  if (getToken()) {
    await checkTokens();
  }
  const response = await apiServer.post(`/upgrade/${shipmentNumber}`, body, {
    headers: { Authorization: getToken() },
    params: { v: 3 },
  });
  return response.data;
};

export const debugGet = async (message) => {
  try {
    await apiServer.get('/debug', {
      params: {
        message,
        userAgent: navigator.userAgent,
        clientTime: new Date().toString(),
      },
    });
  } catch (err) {
    // no-op
  }
};

export const debugPost = async (message) => {
  try {
    await apiServer.post('/debug', {
      message,
      userAgent: navigator.userAgent,
      clientTime: new Date().toString(),
    });
  } catch (err) {
    // no-op
  }
};

export const sendForm = async (url, form) => {
  await apiServer.post(`/forms/${url}`, form);
};

export const sendAttachmentsToS3 = async ({ attachment, customerId }) => {
  await apiServer.post('/order/attachments', convertToFormData({ attachment, customerId }), {
    headers: { 'Content-Type': 'multipart/form-data' },
    timeout: API_TIMEOUT_FOR_FILES,
  });
};

export const sendAttachmentsToCustomsClearanceS3 = async ({ attachment, shipmentNumber }) => {
  await apiServer.post(
    '/customs/attachments', // Consider renaming to clearance
    convertToFormData({ attachment, shipmentNumber }),
    {
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: API_TIMEOUT_FOR_FILES,
    }
  );
};

export const getClearancePrices = async () => {
  try {
    const response = await apiServer.get('/customs/price');
    if (response.status !== 200) {
      const msg = (response.data && response.data.errorMessage) || `${response.status}: ${response.statusText}`;
      throw new Error(msg);
    }
    if (!Array.isArray(response.data.services)) {
      throw new Error('Response is not an array.');
    }
    return response.data;
  } catch (error) {
    testLogger.log('getClearancePrices:FAILED ' + error + ', ' + JSON.stringify(deviceDetect()));
    throw error;
  }
};
export const getDriverPositionForJob = async (shipmentNumber, token) => {
  const res = await apiServer.get(`/delivery/${shipmentNumber}/tracking`, {
    params: { token },
  });
  return res.data;
};

export const startReceiptHeroAuthentication = async () => {
  await checkTokens();
  try {
    const response = await apiServer.get('/receipthero/auth', {
      headers: { Authorization: await getToken() },
    });
    return response.data;
  } catch (error) {
    console.warn('startReceiptHeroAuthentication failed: ', error);
    throw error;
  }
};

export const finishReceiptHeroAuthentication = async (code, state, redirect_uri) => {
  await checkTokens();
  const body = { code, state, redirect_uri };
  try {
    const response = await apiServer.post('/receipthero/auth', body, {
      headers: { Authorization: await getToken() },
    });
    return response.data;
  } catch (error) {
    console.warn('finishReceiptHeroAuthentication failed: ', error);
    throw error;
  }
};

export const revokeReceiptHeroAuthenticaion = async () => {
  await checkTokens();
  try {
    const response = await apiServer.delete('/receipthero/auth', {
      headers: { Authorization: await getToken() },
    });
    return response.data;
  } catch (error) {
    console.warn('revokeReceiptHeroAuthenticaion failed: ', error);
    throw error;
  }
};

export const getExpressParcelStopSuggestion = async (search, language = 'fi') => {
  try {
    const response = await apiServer.get('/express/locations', {
      params: { search, language },
    });
    return response.data;
  } catch (error) {
    console.warn('getExpressParcelStopSuggestion failed:', error);
  }
};

export const getExpressParcelConnections = async ({
  departureStopAreaName,
  arrivalStopAreaName,
  departureStopAreaId,
  arrivalStopAreaId,
  allSchedules,
  departureDate,
  ticketTravelType,
  filters,
  services,
  language,
}) => {
  const response = await apiServer.get('/express/connections', {
    params: {
      departureStopAreaName,
      arrivalStopAreaName,
      departureStopAreaId,
      arrivalStopAreaId,
      allSchedules,
      departureDate,
      ticketTravelType,
      filters,
      services,
      language,
    },
  });
  return response.data;
};

export const getExpressParcelConnection = async ({ id, language }) => {
  try {
    const response = await apiServer.get(`/express/connections/${id}`, {
      params: {
        language,
      },
    });
    return response.data;
  } catch (error) {
    console.warn('getExpressParcelConnection failed:', error);
  }
};

export const getExpressParcelLiveTrackingInfo = async ({ shipmentNumber, language, token }) => {
  try {
    const response = await apiServer.get(`/express/tracking/${shipmentNumber}`, {
      params: {
        language,
        token,
      },
    });
    return response.data;
  } catch (error) {
    console.warn('getExpressParcelLiveTrackingInfo failed:', error);
    throw error;
  }
};

export const getShipmentForDeliveryTransfer = async (shipmentNumber, token) => {
  try {
    const response = await apiServer.get(`/delivery/${shipmentNumber}/shipment`, {
      params: {
        token,
      },
    });
    return response.data;
  } catch (error) {
    console.warn('getShipmentForDeliveryTransfer failed:', error);
    throw error;
  }
};

export const getDeliveryEstimate = async (shipment) => {
  try {
    const { shipmentNumber, productCode, shipmentDate, shipmentStatus, departurePlaceId, destinationPlaceId } =
      shipment;
    const body = {
      productCode,
      shipmentDate,
      shipmentStatus,
      departurePlaceId,
      destinationPlaceId,
    };
    const props = {};
    let token = await getToken();
    if (token) {
      await checkTokens();
      token = await getToken();
      props.headers = { Authorization: token };
    }
    const response = await apiServer.put(`/delivery/estimate/${shipmentNumber}`, body, props);
    return response.data;
  } catch (error) {
    console.warn('getDeliveryEstimate failed:', error);
    return { err: error };
  }
};

export const getShipmentForReturn = async (shipmentNumber, recaptcha) => {
  try {
    const response = await apiServer.get(`/return/shipment/${shipmentNumber}`, {
      params: {
        recaptcha,
      },
      headers: { Authorization: await getToken() },
    });
    return response.data;
  } catch (error) {
    console.warn('getShipmentForReturn failed:', error);
    throw error;
  }
};
