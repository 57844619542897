/** @jsx jsx */
import { jsx, Box, Flex } from 'theme-ui';
import { Themed } from '@theme-ui/mdx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Card = ({ image, title, footer, border = false, children }) => (
  <Flex
    sx={
      border
        ? {
            borderRadius: 2,
            border: '1px solid',
            borderColor: 'border',
            overflow: 'hidden',
            flexDirection: 'column',
          }
        : {
            flexDirection: 'column',
            justifyContent: 'space-between',
          }
    }
  >
    {image && (
      <GatsbyImage
        image={getImage(image)}
        alt=""
        sx={{
          mb: border ? 0 : 3,
          bg: 'secondary',
          borderRadius: border ? 0 : [0, 2],
          /*
          img: {
            mixBlendMode: 'screen',
          }, // */
        }}
      />
    )}
    <Box sx={{ flex: 1, p: border && 24 }}>
      {title && (
        <Themed.h3 as="h2" sx={{ mb: 3 }}>
          {title}
        </Themed.h3>
      )}
      {children}
    </Box>
    {footer && (
      <Box
        sx={{
          bg: 'blueLighter',
          py: 3,
          px: 24,
          '*:first-child': { mt: 0 },
          '*:last-child': { mb: 0 },
          ul: { pl: 0 },
        }}
        dangerouslySetInnerHTML={{ __html: footer.childMarkdownRemark.html }}
      />
    )}
  </Flex>
);

export default Card;
