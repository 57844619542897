/** @jsx jsx */
import { jsx, Box, Flex, Textarea } from 'theme-ui';
import { Themed } from '@theme-ui/mdx';
import React, { useCallback, useState } from 'react';
import * as api from '../utils/api';
import Spinner from './Spinner';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { Form as FormikForm, Field } from 'formik';
import FormField from './FormField';
import RecaptchaButton from './RecaptchaButton';
import CheckboxField from './CheckboxField';
import RadioFieldGroup from './RadioFieldGroup';
import CheckboxFieldGroup from './CheckboxFieldGroup';
import moment from 'moment';
import { showNotification, hideNotification, SEVERITY } from '../state/notifications';
import { handleFormValidationError } from '../utils/formError';
import Tooltip from './Tooltip';
import { InfoIcon } from './Icon';
import { navigate } from 'gatsby';
import { evaluate } from 'mathjs';
import { useDispatch } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import ContentfulRadioField from './ContentfulRadioField';
import { Select } from './Select';
import { sendFormEvent } from '../utils/analytics';
import { isBrowser } from '../utils';
import { useTranslation } from 'react-i18next';

const phoneRegExp = /^\+?[0-9 \-#()]{6,18}$/;
const yearRegExp = /^\d{4}$/;
const decimalRegExp = /^\d{1,4}([.,]\d)?$/;
const ssnRegExp = /^\d{6}[A+-]\d{3}[a-zA-Z0-9]$/;
const vatRegExp = /^\d{7}-\d{1}$/;
const postNumberRegExp = /^\d{5}$/;
const dateRegExp = /^\d{1,2}.\d{1,2}.\d{4}$/;

const generateValidationSchema = (fields, translate, language, vo) => {
  const yupSchema = fields.reduce((schema, field) => {
    //const field = getFormField(formField, vo);
    const { id, type, required, label, values, error } = field;
    const options = {
      ...language,
      defaultValue: error || translate('form.requiredField'),
    };
    // skip informatory details
    if (type === 'info') return schema;
    let validator = null;
    if (type === 'checkbox') {
      validator = yup.bool(translate('form.invalidField', { label, ...options }));
    } else if (type === 'checkboxgroup') {
      validator = yup.array();
    } else {
      validator = yup.string(translate('form.invalidField', { label, ...options }));
    }
    if (type === 'email') {
      validator = validator.email(translate('form.invalidEmailField', { label, ...options }));
    } else if (type === 'phone') {
      validator = validator.matches(phoneRegExp, translate('form.invalidPhoneField', { label, ...options }));
    } else if (type === 'date') {
      validator = validator
        .matches(dateRegExp, translate('form.invalidDateField', { label, ...options }))
        .test('date-valid', translate('form.invalidDateField', { label, ...options }), (value) =>
          value ? moment(value, 'DD.MM.YYYY').isValid() : true
        );
    } else if (type === 'year') {
      validator = validator.matches(yearRegExp, translate('form.invalidYearField', { label, ...options }));
    } else if (type === 'decimal') {
      validator = validator.matches(decimalRegExp, translate('form.invalidDecimalField', { label, ...options }));
    } else if (type === 'ssn') {
      validator = validator.matches(ssnRegExp, translate('form.invalidSSNField', { label, ...options }));
    } else if (type === 'vat') {
      validator = validator.matches(vatRegExp, translate('form.invalidVATField', { label, ...options }));
    } else if (type === 'postNumber') {
      validator = validator.matches(postNumberRegExp, translate('form.invalidPostNumberField', { label, ...options }));
    } else if (type === 'checkbox') {
      if (required) {
        // required checkbox = must be checked
        validator = validator.oneOf([true], translate('form.requiredField', { label, ...options }));
      }
    } else if (type === 'radiogroup' || type === 'select') {
      validator = validator
        .oneOf(
          values.map((val) => val.id),
          translate('form.requiredField', { label, ...options })
        )
        .nullable();
    } else if (type === 'checkboxgroup') {
      validator = validator
        .of(
          yup.string().oneOf(
            values.map((val) => val.id),
            translate('form.requiredField', { label, ...options })
          )
        )
        .ensure();
    }
    if (required) {
      validator = validator.required(translate('form.requiredField', { label, ...options }));
    }
    schema[id] = validator;
    return schema;
  }, {});
  return yup.object().shape({
    ...yupSchema,
    recaptcha: yup.string().required(),
  });
};

const getWidth = (n) => {
  if (typeof n === 'number') return n;
  try {
    if (n.includes('%')) {
      return +n.substring(0, n.indexOf('%')) / 100;
    }
    let number = evaluate(n);
    if (number < 1) return number;
    else return 1;
  } catch (err) {
    console.log(n + ' cannot be mathematically evaluated: ' + err);
    return 1;
  }
};

const getType = (type) => {
  if (type === 'email') return 'email';
  if (type === 'phone') return 'tel';
  // doesn't work correctly if (type === 'date') return 'date';
  return 'text';
};

const isConditionTrue = (condition, values) => {
  try {
    if (Array.isArray(condition.AND)) return !condition.AND.some((and) => !isConditionTrue(and, values));
    if (Array.isArray(condition.OR)) return condition.OR.some((or) => isConditionTrue(or, values));
    if (!Array.isArray(condition)) return false;
    const [field, operator, value] = condition;
    if (operator === '=') return values[field] === value;
    if (operator === '<=') return values[field] <= value;
    if (operator === '>=') return values[field] >= value;
    if (operator === '!=') return values[field] != value;
    if (operator === 'isNull') return !values[field];
    return false;
  } catch (e) {
    console.error(e);
  }
  return false;
};

const mdRenderers = {
  paragraph: 'span',
};

const getFormField = (formField, values) => {
  const label = formField.required ? formField.label + ' *' : formField.label;
  const field = {
    ...formField,
    value: values[formField.id],
    label: label && formField.markdown ? <ReactMarkdown renderers={mdRenderers}>{label}</ReactMarkdown> : label,
  };
  if (!field.condition || !field.condition.if) return field;
  const ifCondition = field.condition.if;
  const thenCondition = field.condition.then || {};
  const finalField = isConditionTrue(ifCondition, values) ? { ...field, ...thenCondition } : field;
  finalField.label = finalField.required ? formField.label + ' *' : formField.label;
  return finalField;
};

const FormComponent = ({
  fields,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  button,
  language,
  errors,
  translate,
  isValid,
  isValidating,
  showNotification,
  values,
  onChange,
}) => {
  const submit = () => {
    if (!isValidating && !isValid) {
      showNotification(translate('form.notValid', null, language), SEVERITY.ERROR);
    }
    handleSubmit();
  };
  const handleChange = useCallback(() => {
    if (!onChange) return;
    onChange(values);
  }, [onChange]);
  return (
    <FormikForm onChange={handleChange}>
      <Flex
        sx={{
          flexWrap: 'wrap',
          flexDirection: 'row',
          mx: -1,
          '> div': { px: 1 },
        }}
      >
        {fields.map((formField) => {
          const field = getFormField(formField, values);
          if (field.hidden) return null;
          let width = '100%';
          let columns = field.columns;
          if (Array.isArray(field.columns)) {
            columns.map((field, i) => (columns[i] = getWidth(field).toFixed(2) * 100 + '%'));
            width = columns;
          } else width = columns ? getWidth(columns).toFixed(2) * 100 + '%' : '100%';
          if (field.type === 'info') {
            const { title, subtitle, description, tooltip, id } = field;
            return (
              <Box sx={{ width: '100%' }} key={id || title || subtitle || description}>
                <Flex
                  sx={{
                    alignItems: 'center',
                    mb: 1,
                    mt: title ? 4 : 0,
                  }}
                >
                  {title && <Themed.h2 sx={{ mt: 0 }}>{title}</Themed.h2>}
                  {tooltip && (
                    <Box sx={{ ml: 2 }}>
                      <Tooltip placement="auto" tooltip={tooltip}>
                        <InfoIcon sx={{ color: 'primary' }} />
                      </Tooltip>
                    </Box>
                  )}
                </Flex>
                <Box>
                  {subtitle && <Themed.h3>{subtitle}</Themed.h3>}
                  {description && <Themed.p>{description}</Themed.p>}
                </Box>
              </Box>
            );
          } else if (
            ['text', 'phone', 'email', 'date', 'decimal', 'year', 'ssn', 'vat', 'postNumber'].includes(field.type)
          ) {
            return (
              <FormField
                key={field.id}
                id={field.id}
                name={field.id}
                width={width}
                placeholder={field.placeholder}
                label={field.label}
                tooltip={field.tooltip}
                type={getType(field.type)}
                required={field.required}
                value={field.value}
              />
            );
          } else if (field.type === 'textarea') {
            return (
              <FormField
                id={field.id}
                key={field.id}
                name={field.id}
                width={width}
                as={Textarea}
                placeholder={field.placeholder}
                label={field.label}
                type="text"
                required={field.required}
                value={field.value}
              />
            );
          } else if (field.type === 'checkbox') {
            return (
              <Box sx={{ mb: 3, width: width }} key={field.id}>
                <Field
                  component={CheckboxField}
                  name={field.id}
                  id={field.id}
                  label={field.label}
                  error={errors[field.id]}
                  type="checkbox"
                  required={field.required}
                  checked={field.value}
                />
              </Box>
            );
          } else if (field.type === 'radiogroup') {
            return (
              <RadioFieldGroup
                key={field.id}
                id={field.id}
                width={width}
                direction={field.direction}
                label={field.label}
                error={errors[field.id]}
                required={field.required}
                value={field.value}
              >
                {field.values.map((val) => {
                  return (
                    <Field
                      key={val.id}
                      component={ContentfulRadioField}
                      name={field.id}
                      id={val.id}
                      value={val.id}
                      fieldValue={field.value}
                      label={val.label}
                      type="radio"
                    />
                  );
                })}
              </RadioFieldGroup>
            );
          } else if (field.type === 'select') {
            return (
              <FormField
                id={field.id}
                key={field.id}
                as={Select}
                width={width}
                name={field.id}
                label={field.label}
                required={field.required}
                value={field.value}
              >
                <option value={''}>{field.defaultValue || translate('form.defaultSelection', null, language)}</option>
                {field.values.map((val) => {
                  return (
                    <option key={val.id} value={val.id}>
                      {val.label}
                    </option>
                  );
                })}
              </FormField>
            );
          } else if (field.type === 'checkboxgroup') {
            return (
              <CheckboxFieldGroup
                key={field.id}
                id={field.id}
                label={field.label}
                error={errors[field.id]}
                required={field.required}
                value={field.value}
              >
                {field.values.map((val) => {
                  return (
                    <Field
                      component={CheckboxField}
                      key={val.id}
                      multiple={true}
                      setFieldValue={setFieldValue}
                      name={field.id}
                      id={val.id}
                      label={val.label}
                      type="checkbox"
                    />
                  );
                })}
              </CheckboxFieldGroup>
            );
          }
          return null;
        })}
      </Flex>
      <RecaptchaButton
        buttonText={button}
        handleSubmit={submit}
        isSubmitting={isSubmitting}
        setFieldValue={setFieldValue}
        translate={translate}
      />
    </FormikForm>
  );
};

const initialValueForType = (type) => {
  if (type === 'checkbox') return false;
  if (type === 'checkboxgroup') return [];
  return '';
};

const initialValueForField = (field) => {
  return field.default || initialValueForType(field.type);
};

const generateInitialValues = (fields, vo) => {
  const values = {};
  for (const field of fields) {
    // skip info fields
    if (field.type === 'info') {
      continue;
    }
    if (field.id) {
      values[field.id] = vo[field.id] ? vo[field.id] : initialValueForField(field);
    }
  }
  return { ...values };
};

const FormikComponent = withFormik({
  mapPropsToValues: ({ vo, fields }) => generateInitialValues(fields, vo),
  validationSchema: ({ fields, translate, language, vo }) => {
    return generateValidationSchema(fields, translate, language, vo);
  },
  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    onSubmit(values, actions);
  },
  displayName: ({ type }) => type + 'InputForm',
})(FormComponent);

const Form = ({ data, language, onSuccess, onError }) => {
  const {
    title,
    form: fields,
    description,
    button,
    type,
    forward,
    externalSubmit,
    disableShowNotification,
    url,
    analyticsEvent,
    fillStartAnalyticsEvent,
  } = data;

  if (analyticsEvent) {
    console.log('Analytics:', analyticsEvent, fillStartAnalyticsEvent);
  }

  const handleFormEvent = (event) => {
    if (!event || !isBrowser) {
      return;
    }

    sendFormEvent(event);
  };

  const { t: translate, i18n } = useTranslation();
  const locale = i18n?.language;
  const [showSpinner, setShowSpinner] = useState(false);
  const [vo, setVO] = useState({});
  const [startedFilling, setStartedFilling] = useState(false);
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (vo, { setSubmitting }) => {
      const values = { ...vo, locale };
      dispatch(hideNotification());
      setShowSpinner(true);
      try {
        if (externalSubmit) {
          let regexp = /###(.*?)###/;
          let obj = regexp.exec(externalSubmit.url);
          let urlStr = externalSubmit.url;
          while (obj !== null) {
            let key = obj[1];
            urlStr = urlStr.replace(obj[0], values[key]);
            obj = regexp.exec(urlStr);
          }
          window.open(urlStr, externalSubmit.target);
        }
        handleFormEvent(analyticsEvent);
        if (url) {
          await api.sendForm(url, values);
        }
        if (forward) {
          navigate(forward);
        }
        if (onSuccess) return onSuccess();
        if (!disableShowNotification) {
          dispatch(showNotification('form.success', SEVERITY.INFO));
        }
        setSubmitting(false);
        setShowSpinner(false);
      } catch (error) {
        setSubmitting(false);
        setShowSpinner(false);
        if (handleFormValidationError(error, dispatch, SEVERITY.ERROR)) {
          return;
        }
        if (onError) return onError(error);
        dispatch(showNotification('form.failed', SEVERITY.ERROR));
      }

      setVO({});
      window && window.scrollTo(0, 0);
    },
    [dispatch, setShowSpinner, locale, analyticsEvent]
  );
  const onChange = useCallback(
    (values) => {
      setVO(values);
      if (!startedFilling && fillStartAnalyticsEvent) {
        handleFormEvent(fillStartAnalyticsEvent);
      }
      setStartedFilling(true);
    },
    [setVO, startedFilling, fillStartAnalyticsEvent]
  );
  const formProps = {
    vo,
    onSubmit,
    translate,
    fields,
    button,
    type,
    language,
    showNotification,
    onChange,
  };
  return (
    <>
      {title && <Themed.h2 sx={{ mt: 0, mb: 4 }}>{title}</Themed.h2>}
      {description && <Themed.p>{description}</Themed.p>}
      <FormikComponent key={language} {...formProps} />
      {showSpinner && <Spinner size="medium" position="fixed" />}
    </>
  );
};

export default Form;
